.statusWrap {
  display: inline-flex;
  align-items: center;
  border-radius: 15px;
  padding: 3px 10px;
  font-size: 12px;
  font-weight: 500;
  color: #1e40af;
  background-color: #dbeafe;
  &__wait {
    background-color: #f3f4f6;
    color: #1f2937;
  }
  &__onprogress {
    background-color: #fef3c7;
    color: #92400e;
  }
  &__rejected {
    background-color: #fee2e2;
    color: #991b1b;
  }
  &__reject {
    background-color: #fce7f3;
    color: #9d174d;
  }
  &__done {
    background-color: #d1fae5;
    color: #065f46;
  }
  &__waiting {
    background-color: #fef4e4;
    color: #faa11d;
  }
}

.statusDot {
  width: 6px;
  height: 6px;
  margin-right: 7px;
  border-radius: 6px;
  background-color: #60a5fa;
  &__wait {
    background-color: #9ca3af;
  }
  &__onprogress {
    background-color: #fbbf24;
  }
  &__rejected {
    background-color: #f87171;
  }
  &__reject {
    background-color: #f472b6;
  }
  &__done {
    background-color: #34d399;
  }
}

@primary-color: #003984;