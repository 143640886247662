.history {
  background-color: @primary-color;
  color: white;
}
.name {
  background-color: @primary-color;
  color: white;
  margin-left: 15;
}
.nonActive {
  background-color: white;
  color: @primary-color;
  margin-left: 15;
}
.buttonStatus {
  border-radius: 10px;
  height: auto;
  border-color: darkgray;
  border-width: 1px;
  width: 25%;
}
.btn {
  background-color: @primary-color;
}
.card {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;
  :global {
    .ant-card-body {
      padding: 1rem;
    }
  }

  .btnAdd {
    margin-left: 1rem;
    :global {
      .ant-btn-circle {
        width: 22px;
        min-width: 22px !important;
        height: 22px;
      }
      .anticon-plus {
        font-size: 12px;
      }
    }
  }
  .btnDelete {
    > span {
      text-decoration-line: underline;
    }
  }
}

.search_container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 1rem;
  margin-bottom: 1.9rem;

  .search_section {
    display: flex;
  }
}

@media screen and (max-width: 640px) {
  .search_section {
    flex-direction: column;
    width: 100%;
    gap: 0.5rem;

    div {
      width: 100%;
    }
  }
}

@primary-color: #003984;