.container {
  padding: 24px 20px 24px 20px;
}

.labelForm {
  margin-bottom: 15px !important;
}

.form {
  height: 40px;
  width: 100%;
}

.headTitle {
  text-align: center;
  font-weight: 700;
  margin-top: 24px;
}

.uploadPhoto {
  width: 100%;
  justify-content: center;
  align-self: center;
  align-items: center;
  display: flex;
  cursor: pointer;
}

.img__wrap {
  position: relative;
  height: 130px;
  width: 130px;
  border-radius: 50%;
  object-fit: cover;
}

.img__description {
  position: absolute;
  top: 50%;
  bottom: 0;
  left: 0;
  right: 0;
  color: #fff;
  visibility: hidden;
  opacity: 0;
  color: red;
  text-align: center;
  background-color: rgba(217, 217, 217, 0.71);
  /* transition effect. not necessary */
  transition: opacity 0.2s, visibility 0.2s;
  border-bottom-left-radius: 140px;
  /* 100px of height + 10px of border */
  border-bottom-right-radius: 140px;
  width: 100%;
  height: 50%;
}

//   .img__wrap:hover .img__description {
//     visibility: visible;
//     opacity: 1;
//   }

.titleSection {
  margin-top: 20px;
  font-weight: bold;
}

@primary-color: #003984;