.wrap_tb {
  position: relative;
  overflow-x: auto;

  .wrap_reload {
    position: absolute;
    top: 65px;
    z-index: 99;
    width: 100%;
    display: flex;
    justify-content: center;

    .pad {
      width: 30px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #f9fafb;
      border: 1px solid #e5e7eb;
      border-radius: 50%;
    }
  }
}

@primary-color: #003984;