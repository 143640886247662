@import "~antd/dist/antd.less";
@import url("https://fonts.googleapis.com/css2?family=Inter:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Inter", sans-serif;
  letter-spacing: 0.5px;
}

input {
  font-family: "Inter", sans-serif;
  letter-spacing: 0.5px;
  font-weight: 400;
}

.ql-container.ql-snow {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;
  border: 1px solid #f0f0f0 !important;
}

.ql-toolbar.ql-snow {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;
  border: 1px solid #f0f0f0 !important;
}

.ql-align-right {
  text-align: right;
}

.ql-align-center {
  text-align: center;
}

.ql-align-left {
  text-align: left;
}

.ql-align-justify {
  text-align: justify;
}

.ql-container {
  min-height: 25rem;
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
}

.ql-editor {
  height: 100%;
  flex: 1;
  overflow-y: auto;
  width: 100%;
}

// Animations
.animate-fadein {
  -webkit-animation-name: fadein;
  /* Safari 4.0 - 8.0 */
  -webkit-animation-duration: 1s;
  /* Safari 4.0 - 8.0 */
  animation-name: fadein;
  animation-duration: 1s;
}

@keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* Cursor */
.cursor {
  &-pointer {
    cursor: pointer;
  }
}

/* Ant Design Overrides */
.ant {
  &-tabs {
    margin: 0;

    &-tab {
      text-transform: uppercase;
      font-size: 12px;
      font-weight: 500;
      color: #6b7280;

      &-active {
        border-bottom: 3px solid @primary-color;
      }
    }
  }

  &-tabs-nav {
    margin: 0 !important;
  }

  &-layout {
    background-color: #ffffff;
  }

  &-layout-header {
    border-bottom: 1px #f4f4f4 solid;
  }

  &-layout-sider-collapsed {
    width: auto !important;
    min-width: auto !important;
    flex: none !important;
  }

  &-row {
    margin-left: 0;
    margin-right: 0;
  }

  &-card {
    border-radius: 12px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;

    &-head {
      font-family: "Montserrat", sans-serif;
      border-bottom: transparent;
    }

    &-body {
      padding: 0 24px 24px 24px;
    }
  }

  &-form-item {
    &-label {
      > label {
        font-weight: 500;
        color: #1f1e2d;
      }
    }
  }

  &-form-item-explain-error {
    font-size: 11px;
    font-style: italic;
  }

  &-btn {
    &-outline {
      border: 2px @primary-color solid;

      > span {
        font-weight: 500;
        color: @primary-color;
      }
    }

    &-primary {
      > span {
        font-weight: 600;
        color: white;
      }
    }

    &-link {
      box-shadow: 0px 0px 0px;
    }

    &-gray {
      border-color: rgb(133, 133, 133);
      color: rgb(133, 133, 133);
    }

    &:not(.ant-btn-circle) {
      border-radius: 10px;
    }
  }

  &-menu-item {
    .anticon {
      margin-right: 16px;
    }
  }

  &-menu-submenu-open {
    background-color: rgb(255, 255, 255) !important;
  }

  &-menu {
    &-inline-collapsed {
      width: 40px !important;
    }
  }

  &-menu-title {
    &-content {
      font-weight: 500;
      font-size: 16px;
    }
  }

  &-menu-submenu-title {
    padding-left: 8px !important;

    .anticon {
      background-color: rgb(255, 255, 255) !important;
      margin-right: 16px;
    }
  }

  &-menu-sub {
    border-right: none;
    background-color: rgb(255, 255, 255) !important;

    &-item-selected {
      border-radius: 6px;
      background-color: #e4dcd3 !important;

      &:after {
        opacity: 0 !important;
      }

      &:active {
        background-color: #e4dcd3 !important;
        border-radius: 6px !important;
      }

      &:focus {
        background-color: #e4dcd3 !important;
        border-radius: 6px !important;
      }
    }

    &-item {
      &:active {
        background-color: #e4dcd3 !important;
        border-radius: 6px !important;
      }

      &:focus {
        background-color: #e4dcd3 !important;
        border-radius: 6px !important;
      }
    }

    &-sub {
      background-color: rgb(255, 255, 255) !important;
    }
  }

  &-menu-sub.ant-menu-inline {
    background-color: rgb(255, 255, 255) !important;
  }

  &-table-container table {
    border-radius: 8px;
    border: 1px solid #e5e7eb;

    > thead > tr:first-child th:first-child {
      border-top-left-radius: 8px;
    }

    > thead > tr:last-child th:last-child {
      border-top-right-radius: 8px;
    }
  }

  &-table-thead {
    th.ant-table-column-has-sorters {
      background-color: #f9fafb;
    }

    > tr > th {
      font-size: 12px;
      font-weight: 500;
      text-transform: uppercase;
      letter-spacing: 0.05em;
      color: #6b7280;
      background-color: #f9fafb;
      border-bottom: solid 1px #e5e7eb;
    }
  }

  &-table-tbody {
    color: #6b7280;
    font-size: 14px;
    font-weight: 400;

    > tr:last-child td:first-child {
      border-bottom-left-radius: 8px;
    }

    > tr:last-child td:last-child {
      border-bottom-right-radius: 8px;
    }

    > tr > td:first-child {
      font-weight: 500;
      color: #111827;
      background-color: #fff;
      border-bottom: solid 1px #e5e7eb;
    }

    > tr.ant-table-row:hover > td {
      background-color: #e6f7ff;
      transition: 1s;
    }
  }

  &-pagination-item {
    border: 1px solid #d1d5db;

    a {
      color: #6b7280;
    }
  }

  &-pagination-item-active {
    background-color: #eef2ff;
    border: 1px solid #6366f1;

    a {
      color: #4f46e5;
    }
  }

  .datatable-pagination &-pagination-next,
  .datatable-pagination &-pagination-prev {
    display: none;
  }

  .datatable-control &-pagination-item,
  .datatable-control &-pagination-jump-next,
  .datatable-control &-pagination-jump-prev {
    display: none;
  }

  &-pagination-prev &-pagination-item-link {
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
  }

  &-pagination-next &-pagination-item-link {
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
  }

  &-input,
  &-input-affix-wrapper {
    border: 0.5px #d2d2d5 solid;
    border-radius: 6px;
  }

  &-select-selector {
    border: 0.5px #d2d2d5 solid;
    border-radius: 6px !important;
  }

  &-descriptions-bordered &-descriptions-item-label {
    padding-left: 0;
  }

  &-descriptions-bordered &-descriptions-view {
    border: none !important;
  }

  &-descriptions-bordered &-descriptions-item-label {
    background-color: transparent;
  }

  &-descriptions-bordered &-descriptions-item-label,
  &-descriptions-bordered &-descriptions-item-content {
    border: none;
  }

  &-menu {
    border-right: none;
    color: #4b5563;

    &-item-selected {
      border-radius: 6px;
      background-color: #f3f4f6 !important;
      color: #111827;

      &:after {
        opacity: 0 !important;
      }

      &:active {
        background-color: #f3f4f6 !important;
        border-radius: 6px !important;
      }

      &:focus {
        background-color: #f3f4f6 !important;
        border-radius: 6px !important;
      }
    }

    &-item {
      &:active {
        background-color: #f3f4f6 !important;
        border-radius: 6px !important;
      }

      &:focus {
        background-color: #f3f4f6 !important;
        border-radius: 6px !important;
      }
    }

    &-sub {
      background-color: #f3f4f6 !important;
    }

    &-submenu-selected {
      color: #111827;
    }
  }

  &-menu-inline {
    &::after {
      display: none;
    }
  }

  &-modal {
    &-content {
      border-radius: 8px;
    }

    &-header {
      border-radius: 10px;
      border-bottom: 0px;
    }

    &-body {
      padding: 0;
    }
  }

  &-typography {
    line-height: normal;
  }
}

.nav-right-menu.ant-menu-dark.ant-menu-horizontal {
  background-color: transparent;
}

.svg-fill {
  &-purple {
    fill: #9c27b0;
  }

  &-blue {
    fill: #2196f3;
  }

  &-green {
    fill: #8bc34a;
  }

  &-amber {
    fill: #ffc107;
  }

  &-pink {
    fill: #e91e63;
  }
}

select[disabled] {
  color: #000;
  font-weight: bold;
}

.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  color: #000 !important;
  background-color: white !important;
}

.ant-picker.ant-picker-disabled {
  background: #ffffff !important;
  cursor: not-allowed !important;
  border: 1px solid #8e989e5c !important;
  border-radius: 5px !important;
}

.ant-picker-input > input[disabled] {
  color: #000 !important;
}

.ant-input[disabled] {
  background: #ffffff !important;
  color: #000 !important;
}

@primary-color: #003984;