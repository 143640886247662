.segmentedWrap {
  display: flex;
  cursor: pointer;
  @media (max-width: 786px) {
    flex-direction: column;
    width: 100%;
  }
}

.segmentedItem {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 38px;
  padding: 0px 12px;
  border-radius: 6px;
  font-weight: 500;
  font-size: 14px;

  &__active {
    background-color: #e0e7ff;
    color: #4338ca;
    transition: color ease-in 0.5s, all ease-in 0.5s;
  }

  &__nonActive {
    color: #6b7280;
  }

  + .segmentedItem {
    margin-left: 8px;
  }
}

@primary-color: #003984;