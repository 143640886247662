.inputWrap {
  width: min(300px, 100%);
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border: 1px solid #d1d5db;
  border-radius: 6px;
  margin-right: 8px;
}

@primary-color: #003984;