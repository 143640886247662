.ant-form {
  height: 100% !important;
}
.ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
  background: #faad14 !important;
}

#ttd_letter {
  position: fixed;
}

.make-a-container {
  container-type: inline-size;
}
