.custom_submenu {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0;
}

.custom_menu_item_title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.custom_menu_item_title > span:first-child {
  display: flex;
  gap: 0.5rem;
}

// .custom_menu_item_title *:last-child {
//   margin-left: auto;
// }

.sidebar_header {
  padding-inline: 0.65rem;
  padding-top: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  margin-bottom: 0.75rem;

  & > *:last-child {
    font-size: 1.25rem;
    color: #9ca3af;
  }
}

@primary-color: #003984;