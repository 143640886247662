.root {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  height: 100%;
}

.content {
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  width: 100%;
  padding: 24px 32px;
}

.contentSub {
  width: 100%;
  // height: 100%;
}

.footer {
  position: static;
  bottom: 0;
  right: 0;
  width: 100%;
}

.bg_opacity {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.45);
}

@primary-color: #003984;