.wrapper_notif {
  display: flex;
  align-items: center;
  margin-right: 12px;
  cursor: pointer;
  position: relative;
  padding: 8px 14px;
  background-color: #f5f6f7;
  line-height: normal;
  border-radius: 100px;
  font-size: 12px;
  font-weight: 500;
  color: #6b7280;

  &_active {
    color: #f9284e;
    background-color: #fee5ea;
  }

  .dot {
    height: 20px;
    background-color: #ffffff;
    position: absolute;
    border-radius: 20px;
    top: -5px;
    right: -5px;
    display: flex;
    justify-content: center;
    align-items: center;

    .text {
      letter-spacing: 0px;
      height: 16px;
      border-radius: 16px;
      background-color: red;
      font-size: 10px;
      font-weight: 600;
      color: #ffffff;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .red_icon {
    filter: invert(44%) sepia(99%) saturate(4757%) hue-rotate(330deg) brightness(93%) contrast(110%);
  }
}

.notif_content::-webkit-scrollbar {
  display: none;
}

.notif_content {
  background-color: #fff;
  min-width: 540px;
  max-width: 500px;
  max-height: 80vh;
  z-index: 99;
  top: 45px;
  right: 130px;
  border-radius: 3px;
  line-height: normal;
  overflow: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;

  .wrap_title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 25px;

    h2 {
      font-size: 21px;
      margin: 0;
      color: #172b4d;
    }

    .right {
      color: #42526e;
      display: flex;
      align-items: center;
    }
  }

  .wrap_type {
    display: flex;
    padding: 0px 25px;
    border-bottom: 1px solid #e5e7eb;

    .list_type {
      font-weight: 500;
      margin-right: 20px;
      padding: 5px 0px;

      &_active {
        border-bottom: 2px solid @primary-color;
        color: @primary-color;
      }
    }
  }

  .list_item {
    border-bottom: 1px solid #e5e7eb;
    padding: 0px 25px;
    font-size: 12px;
    cursor: pointer;

    &_murder {
      padding: 25px;
      border-top: 1px solid #e5e7eb;
    }

    .loading {
      height: 115px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .subject {
      font-weight: 700;
      color: @primary-color;
    }

    .title {
      padding-top: 5px;
      padding-bottom: 5px;
      color: #999999;
      font-weight: 700;
      position: relative;

      .dot {
        right: 0;
        bottom: 0;
        position: absolute;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: #0052cc;
      }
    }

    .description {
      color: #999999;
      font-weight: 400;
      padding-bottom: 1rem;
    }

    .header {
      display: flex;
      justify-content: space-between;
      padding-top: 1rem;

      .date {
        font-style: italic;
        color: #c4c4c4;
      }
    }
  }

  .list_item:hover {
    background-color: #dae9fe;
  }
}

.logo {
  margin-left: 16px;
  // height: 20px;
}

aside::-webkit-scrollbar {
  display: none;
}

.custom {
  .ant-popover-inner-content {
    padding: 0 !important;
  }
}

.profile_summary {
  display: flex;
  flex-direction: column;
}

@media screen and (max-width: 640px) {
  .notifTitle {
    display: none;
  }

  .wrapper_notif {
    width: 3rem;
  }

  .profile_summary {
    display: none;
  }
}

@primary-color: #003984;