.button_form_wrap {
  position: relative;
  border-radius: 8px;
  display: flex;
  background-color: @primary-color;
  font-weight: 600;
  color: #fff;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  border: solid 1px transparent;
  line-height: normal;
  height: 38px;
  padding: 0px 16px;

  &_approved {
    background-color: #3fb270;
  }

  &_reject {
    background-color: #f84337;
  }

  &_close {
    border: solid 1px #f84337;
    background-color: white;
    color: #f84337;
  }

  &_save_medical {
    border: solid 1px #92d2ab;
    background-color: white;
    color: #92d2ab;
  }

  &_disabled {
    border: 1px solid #d1d5db !important;
    background-color: #d1d5db !important;
    color: #a1a1a1 !important;
    cursor: not-allowed;
  }

  &_icon {
    border-radius: 50%;
    padding: 5px 7px;
    background-color: transparent;
    color: @primary-color;
    font-size: 14px;
  }

  &_noBorder {
    border: none;
    background-color: white;
    color: #6b7280;
  }

  &_1 {
    border: solid 1px @primary-color;
    background-color: white;
    color: @primary-color;
  }

  &_2 {
    background-color: #eaf1fa;
    color: @primary-color;
  }
  &_3 {
    background-color: #eaf1fa;
    color: @primary-color;
    border: 1px solid @primary-color;
  }

  .btn_content::-webkit-scrollbar {
    display: none;
  }

  + .button_form_wrap {
    @media (min-width: 786px) {
      margin-left: 8px;
    }
  }
}

.btn_list {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 12px 0px;
  text-align: left;
  font-size: 14px;
  font-weight: 400;
  color: #111827;
  cursor: pointer;

  .icon {
    width: 32px;
    height: 32px;
    object-fit: cover;
    margin-right: 8px;

    .full {
      height: 100%;
      width: 100%;
    }
  }

  + .btn_list {
    border-top: 1px solid #efefef;
  }
}

@primary-color: #003984;