.container {
  padding: 24px 24px 24px 24px;
  .form {
    height: 40px;
  }
  .headTitle {
    text-align: center;
    font-weight: 700;
    margin-top: 24px;
  }
}

@primary-color: #003984;