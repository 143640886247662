.wrap_title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  line-height: normal;
  .title {
    font-size: 24px;
    font-weight: 600;
    color: #111827;
  }
  .subtitle {
    font-weight: 600;
    color: @primary-color;
    cursor: pointer;
  }
}

.wrap_status::-webkit-scrollbar {
  display: none;
}

.top {
  margin-top: 24px;
  &_active {
    display: none;
  }
  &_wrap_custom_date {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.listflow_container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0.25rem;
}

.wrap_status {
  display: flex;
  align-items: center;
  overflow: scroll;
  white-space: nowrap;
  /* Hidden scroll bar */
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  .btn {
    padding: 6px 19px;
    border: 1px solid @primary-color;
    background-color: #ffffff;
    color: @primary-color;
    border-radius: 12px;
    font-weight: 500;
    cursor: pointer;
    &_active {
      background-color: @primary-color;
      color: #ffffff;
    }
    + .btn {
      margin-left: 8px;
    }
  }
}

.footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
  &_margin {
    margin-block: 14px;
  }
}

@media screen and (max-width: 480px) {
  .listflow_container {
    grid-template-columns: 1fr;
  }

  .footer {
    flex-direction: column;

    *:last-child {
      margin: 0;
    }
  }
}

@primary-color: #003984;