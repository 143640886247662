.language_select_container {
  border-radius: 8px;
  padding: 0.5rem 1rem;
  box-shadow: 1px 1px 5px 0 rgba(0, 0, 0, 0.5);
  width: 10rem;
  position: fixed;
  // right: 50%;
  bottom: 5px;
  z-index: 2000;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  background-color: #f2f2f2;
}

.language_select_container:hover {
  .toast_message {
    visibility: visible;
    opacity: 1;
  }
}

.toast_message {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  bottom: 100%;
  color: red;
  transition: 100ms all ease-in;
  background-color: #f2f2f2;
  padding: 0.5rem;
}

@primary-color: #003984;