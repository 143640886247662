.wrap_pg {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 15px;
  flex-wrap: wrap;
  gap: 1rem;

  @media (max-width: 810px) {
    flex-direction: column;
  }
  .wrap_left {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 1rem;
  }
  .txt {
    font-weight: 500;
    margin-left: 20px;
  }
}

@primary-color: #003984;